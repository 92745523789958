exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-cron-tsx": () => import("./../../../src/pages/admin/cron.tsx" /* webpackChunkName: "component---src-pages-admin-cron-tsx" */),
  "component---src-pages-admin-index-tsx": () => import("./../../../src/pages/admin/index.tsx" /* webpackChunkName: "component---src-pages-admin-index-tsx" */),
  "component---src-pages-admin-search-tsx": () => import("./../../../src/pages/admin/search.tsx" /* webpackChunkName: "component---src-pages-admin-search-tsx" */),
  "component---src-pages-admin-users-tsx": () => import("./../../../src/pages/admin/users.tsx" /* webpackChunkName: "component---src-pages-admin-users-tsx" */),
  "component---src-pages-affinity-explorer-tsx": () => import("./../../../src/pages/affinity-explorer.tsx" /* webpackChunkName: "component---src-pages-affinity-explorer-tsx" */),
  "component---src-pages-api-access-tsx": () => import("./../../../src/pages/api/access.tsx" /* webpackChunkName: "component---src-pages-api-access-tsx" */),
  "component---src-pages-api-tsx": () => import("./../../../src/pages/api.tsx" /* webpackChunkName: "component---src-pages-api-tsx" */),
  "component---src-pages-apple-arcade-top-games-tsx": () => import("./../../../src/pages/apple-arcade-top-games.tsx" /* webpackChunkName: "component---src-pages-apple-arcade-top-games-tsx" */),
  "component---src-pages-browse-tsx": () => import("./../../../src/pages/browse.tsx" /* webpackChunkName: "component---src-pages-browse-tsx" */),
  "component---src-pages-ccu-rankings-tsx": () => import("./../../../src/pages/ccu-rankings.tsx" /* webpackChunkName: "component---src-pages-ccu-rankings-tsx" */),
  "component---src-pages-changelog-tsx": () => import("./../../../src/pages/changelog.tsx" /* webpackChunkName: "component---src-pages-changelog-tsx" */),
  "component---src-pages-company-hit-list-tsx": () => import("./../../../src/pages/company-hit-list.tsx" /* webpackChunkName: "component---src-pages-company-hit-list-tsx" */),
  "component---src-pages-compare-tsx": () => import("./../../../src/pages/compare.tsx" /* webpackChunkName: "component---src-pages-compare-tsx" */),
  "component---src-pages-country-explorer-[abbr]-tsx": () => import("./../../../src/pages/country-explorer/[abbr].tsx" /* webpackChunkName: "component---src-pages-country-explorer-[abbr]-tsx" */),
  "component---src-pages-country-explorer-index-tsx": () => import("./../../../src/pages/country-explorer/index.tsx" /* webpackChunkName: "component---src-pages-country-explorer-index-tsx" */),
  "component---src-pages-details-[appid]-affinity-explorer-index-tsx": () => import("./../../../src/pages/details/[appid]/affinity-explorer/index.tsx" /* webpackChunkName: "component---src-pages-details-[appid]-affinity-explorer-index-tsx" */),
  "component---src-pages-details-[appid]-affinity-explorer-tsx": () => import("./../../../src/pages/details/[appid]/affinity-explorer/[...].tsx" /* webpackChunkName: "component---src-pages-details-[appid]-affinity-explorer-tsx" */),
  "component---src-pages-details-[appid]-hype-annotator-tsx": () => import("./../../../src/pages/details/[appid]/hype-annotator.tsx" /* webpackChunkName: "component---src-pages-details-[appid]-hype-annotator-tsx" */),
  "component---src-pages-details-[appid]-index-tsx": () => import("./../../../src/pages/details/[appid]/index.tsx" /* webpackChunkName: "component---src-pages-details-[appid]-index-tsx" */),
  "component---src-pages-details-[appid]-revenue-predictor-tsx": () => import("./../../../src/pages/details/[appid]/revenue-predictor.tsx" /* webpackChunkName: "component---src-pages-details-[appid]-revenue-predictor-tsx" */),
  "component---src-pages-details-index-tsx": () => import("./../../../src/pages/details/index.tsx" /* webpackChunkName: "component---src-pages-details-index-tsx" */),
  "component---src-pages-ebooks-tsx": () => import("./../../../src/pages/ebooks.tsx" /* webpackChunkName: "component---src-pages-ebooks-tsx" */),
  "component---src-pages-epic-games-store-top-games-tsx": () => import("./../../../src/pages/epic-games-store-top-games.tsx" /* webpackChunkName: "component---src-pages-epic-games-store-top-games-tsx" */),
  "component---src-pages-epic-top-sellers-tsx": () => import("./../../../src/pages/epic-top-sellers.tsx" /* webpackChunkName: "component---src-pages-epic-top-sellers-tsx" */),
  "component---src-pages-favorite-apps-tsx": () => import("./../../../src/pages/favorite-apps.tsx" /* webpackChunkName: "component---src-pages-favorite-apps-tsx" */),
  "component---src-pages-follower-velocity-tsx": () => import("./../../../src/pages/follower-velocity.tsx" /* webpackChunkName: "component---src-pages-follower-velocity-tsx" */),
  "component---src-pages-followers-tsx": () => import("./../../../src/pages/followers.tsx" /* webpackChunkName: "component---src-pages-followers-tsx" */),
  "component---src-pages-forecast-tsx": () => import("./../../../src/pages/forecast.tsx" /* webpackChunkName: "component---src-pages-forecast-tsx" */),
  "component---src-pages-fortnite-top-maps-tsx": () => import("./../../../src/pages/fortnite-top-maps.tsx" /* webpackChunkName: "component---src-pages-fortnite-top-maps-tsx" */),
  "component---src-pages-hype-annotator-tsx": () => import("./../../../src/pages/hype-annotator.tsx" /* webpackChunkName: "component---src-pages-hype-annotator-tsx" */),
  "component---src-pages-hype-conversion-significant-post-release-tsx": () => import("./../../../src/pages/hype-conversion-significant-post-release.tsx" /* webpackChunkName: "component---src-pages-hype-conversion-significant-post-release-tsx" */),
  "component---src-pages-hype-conversion-tag-ranking-tsx": () => import("./../../../src/pages/hype-conversion-tag-ranking.tsx" /* webpackChunkName: "component---src-pages-hype-conversion-tag-ranking-tsx" */),
  "component---src-pages-hype-tsx": () => import("./../../../src/pages/hype.tsx" /* webpackChunkName: "component---src-pages-hype-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-index-tsx": () => import("./../../../src/pages/login/index.tsx" /* webpackChunkName: "component---src-pages-login-index-tsx" */),
  "component---src-pages-login-password-reset-tsx": () => import("./../../../src/pages/login/password-reset.tsx" /* webpackChunkName: "component---src-pages-login-password-reset-tsx" */),
  "component---src-pages-meta-quest-vr-top-games-tsx": () => import("./../../../src/pages/meta-quest-vr-top-games.tsx" /* webpackChunkName: "component---src-pages-meta-quest-vr-top-games-tsx" */),
  "component---src-pages-monthly-all-tsx": () => import("./../../../src/pages/monthly-all.tsx" /* webpackChunkName: "component---src-pages-monthly-all-tsx" */),
  "component---src-pages-most-reviewed-tsx": () => import("./../../../src/pages/most-reviewed.tsx" /* webpackChunkName: "component---src-pages-most-reviewed-tsx" */),
  "component---src-pages-nocturne-tsx": () => import("./../../../src/pages/nocturne.tsx" /* webpackChunkName: "component---src-pages-nocturne-tsx" */),
  "component---src-pages-pitch-decks-tsx": () => import("./../../../src/pages/pitch-decks.tsx" /* webpackChunkName: "component---src-pages-pitch-decks-tsx" */),
  "component---src-pages-playstation-top-downloads-tsx": () => import("./../../../src/pages/playstation-top-downloads.tsx" /* webpackChunkName: "component---src-pages-playstation-top-downloads-tsx" */),
  "component---src-pages-post-release-monthly-tsx": () => import("./../../../src/pages/post-release-monthly.tsx" /* webpackChunkName: "component---src-pages-post-release-monthly-tsx" */),
  "component---src-pages-post-release-tsx": () => import("./../../../src/pages/post-release.tsx" /* webpackChunkName: "component---src-pages-post-release-tsx" */),
  "component---src-pages-post-release-weekly-tsx": () => import("./../../../src/pages/post-release-weekly.tsx" /* webpackChunkName: "component---src-pages-post-release-weekly-tsx" */),
  "component---src-pages-pre-release-tag-ranking-tsx": () => import("./../../../src/pages/pre-release-tag-ranking.tsx" /* webpackChunkName: "component---src-pages-pre-release-tag-ranking-tsx" */),
  "component---src-pages-pro-tsx": () => import("./../../../src/pages/pro/[...].tsx" /* webpackChunkName: "component---src-pages-pro-tsx" */),
  "component---src-pages-publisher-developer-index-tsx": () => import("./../../../src/pages/publisher-developer/index.tsx" /* webpackChunkName: "component---src-pages-publisher-developer-index-tsx" */),
  "component---src-pages-publisher-developer-tsx": () => import("./../../../src/pages/publisher-developer/[...].tsx" /* webpackChunkName: "component---src-pages-publisher-developer-tsx" */),
  "component---src-pages-revenue-predictor-tsx": () => import("./../../../src/pages/revenue-predictor.tsx" /* webpackChunkName: "component---src-pages-revenue-predictor-tsx" */),
  "component---src-pages-sale-events-[event-id]-tsx": () => import("./../../../src/pages/sale-events/[event-id].tsx" /* webpackChunkName: "component---src-pages-sale-events-[event-id]-tsx" */),
  "component---src-pages-sale-events-index-tsx": () => import("./../../../src/pages/sale-events/index.tsx" /* webpackChunkName: "component---src-pages-sale-events-index-tsx" */),
  "component---src-pages-sales-explorer-[appid]-tsx": () => import("./../../../src/pages/sales-explorer/[appid].tsx" /* webpackChunkName: "component---src-pages-sales-explorer-[appid]-tsx" */),
  "component---src-pages-sales-explorer-index-tsx": () => import("./../../../src/pages/sales-explorer/index.tsx" /* webpackChunkName: "component---src-pages-sales-explorer-index-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */),
  "component---src-pages-steam-release-heatmap-tsx": () => import("./../../../src/pages/steam-release-heatmap.tsx" /* webpackChunkName: "component---src-pages-steam-release-heatmap-tsx" */),
  "component---src-pages-studios-to-watch-tsx": () => import("./../../../src/pages/studios-to-watch.tsx" /* webpackChunkName: "component---src-pages-studios-to-watch-tsx" */),
  "component---src-pages-switch-top-downloads-tsx": () => import("./../../../src/pages/switch-top-downloads.tsx" /* webpackChunkName: "component---src-pages-switch-top-downloads-tsx" */),
  "component---src-pages-tag-explorer-index-tsx": () => import("./../../../src/pages/tag-explorer/index.tsx" /* webpackChunkName: "component---src-pages-tag-explorer-index-tsx" */),
  "component---src-pages-tag-explorer-tsx": () => import("./../../../src/pages/tag-explorer/[...].tsx" /* webpackChunkName: "component---src-pages-tag-explorer-tsx" */),
  "component---src-pages-trending-[role]-tsx": () => import("./../../../src/pages/trending/[role].tsx" /* webpackChunkName: "component---src-pages-trending-[role]-tsx" */),
  "component---src-pages-trending-focus-tsx": () => import("./../../../src/pages/trending-focus.tsx" /* webpackChunkName: "component---src-pages-trending-focus-tsx" */),
  "component---src-pages-trending-tag-ranking-tsx": () => import("./../../../src/pages/trending-tag-ranking.tsx" /* webpackChunkName: "component---src-pages-trending-tag-ranking-tsx" */),
  "component---src-pages-trending-tsx": () => import("./../../../src/pages/trending.tsx" /* webpackChunkName: "component---src-pages-trending-tsx" */),
  "component---src-pages-weekly-all-tsx": () => import("./../../../src/pages/weekly-all.tsx" /* webpackChunkName: "component---src-pages-weekly-all-tsx" */),
  "component---src-pages-wishlist-stats-tsx": () => import("./../../../src/pages/wishlist-stats.tsx" /* webpackChunkName: "component---src-pages-wishlist-stats-tsx" */),
  "component---src-pages-wishlists-tsx": () => import("./../../../src/pages/wishlists.tsx" /* webpackChunkName: "component---src-pages-wishlists-tsx" */),
  "component---src-pages-xbox-recent-downloads-tsx": () => import("./../../../src/pages/xbox-recent-downloads.tsx" /* webpackChunkName: "component---src-pages-xbox-recent-downloads-tsx" */)
}

