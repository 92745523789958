import {
  format,
  isFuture,
  isMatch,
  parse,
  parseISO,
  startOfToday,
} from "date-fns";
import { match, P } from "ts-pattern";

import { gdcoLocale } from "./locale";

type ChangeDateFn = (jsdate: Date, change: number) => Date;

/**
 * Formats the date as YYYY-MM-DD
 */
export const isodate = (
  date: NormalizeDateInput = startOfToday(),
  opts?: NormalizeDateOpts,
) => {
  date = normalizeDate(date, opts);
  return format(date, "yyyy-MM-dd");
};

/**
 * Calls a function to change the provided date (e.g. `subDays`)
 * and formats the value as YYYY-MM-DD
 */
export const isodatePreviousDate = (
  date: NormalizeDateInput,
  changeDateFn: ChangeDateFn,
  changeBy = 1,
  opts?: NormalizeDateOpts,
) => {
  date = normalizeDate(date, opts);
  const previousDate = changeDateFn(date, changeBy);
  return format(previousDate, "yyyy-MM-dd");
};

/**
 * Calls a function to change the provided date (e.g. `addDays`)
 * and formats the value as YYYY-MM-DD
 *
 * Returns `N/A` if the date is in the future.
 */
export const isodateNextDateNoFuture = (
  date: NormalizeDateInput,
  changeDateFn: ChangeDateFn,
  changeBy = 1,
  opts?: NormalizeDateOpts,
) => {
  date = normalizeDate(date, opts);
  const nextDate = changeDateFn(date, changeBy);
  if (isFuture(nextDate)) return gdcoLocale.na;
  return format(nextDate, "yyyy-MM-dd");
};

/**
 * Returns the provided date string in the format of `12 Jan 2023`
 *
 * Doesn't check for the validity of the parameter.
 */
export const ddMMMyyyyDate = (
  date: NormalizeDateInput,
  opts?: NormalizeDateOpts,
) => {
  date = normalizeDate(date, opts);
  return format(date, "dd MMM yyyy");
};

/**
 * Returns the provided date string in the format of `12 Jan 2023 12:34:56`
 *
 * Doesn't check for the validity of the parameter.
 */
export const ddMMMyyyyDateTime = (
  date: NormalizeDateInput,
  opts?: NormalizeDateOpts,
) => {
  date = normalizeDate(date, opts);
  return format(date, "dd MMM yyyy HH:mm:ss");
};

/**
 * Returns a Date object from a string like `18 Jan 2023`
 *
 * The Date object is initiated at the start of the day in UTC for the given ISO string
 *
 */
export const getDateFromddMMMyyyy = (dateString: string) => {
  if (!isValidDateyddMMMyyyy(dateString)) {
    throw new Error("Invalid date");
  }
  const inputDate = parse(dateString, "dd MMM yyyy", startOfToday());
  const utcDate = Date.UTC(
    inputDate.getFullYear(),
    inputDate.getMonth(),
    inputDate.getDate(),
  );
  return new Date(utcDate);
};

/**
 * Returns a Date object from an ISO date string
 *
 * The Date object is initiated at the start of the day in UTC for the given ISO string
 */
export const getDateFromISO = (isodate: string) => {
  const inputDate = parseISO(isodate);
  const utcDate = Date.UTC(
    inputDate.getFullYear(),
    inputDate.getMonth(),
    inputDate.getDate(),
  );
  return new Date(utcDate);
};

/**
 * Returns the provided date string in the format of `January 2023`
 *
 * Doesn't check for the validity of the parameter.
 */
export const MMMMyyyyDate = (isodate: string | Date) => {
  return format(
    typeof isodate === "string" ? parseISO(isodate) : isodate,
    "MMMM yyyy",
  );
};

/** Checks if the provided date is a valid YYYY-MM-DD string */
export const isValidDateyyyyMMdd = (date: unknown): date is string => {
  return typeof date === "string" && isMatch(date, "yyyy-MM-dd");
};

/** Checks if the provided date is a valid dd MMM yyyy string (18 Jan 2023) */
export const isValidDateyddMMMyyyy = (date: unknown): date is string => {
  return typeof date === "string" && isMatch(date, "dd MMM yyyy");
};

/**
 * Normalizes the provided date to a Date object
 */
export function normalizeDate(
  date: NormalizeDateInput,
  opts?: NormalizeDateOpts,
) {
  opts = { numberHasMilliseconds: false, ...opts };

  return match(date)
    .returnType<Date>()
    .with(
      P.number,
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers -- milliseconds
      (d) => new Date(d * (opts.numberHasMilliseconds ? 1 : 1000)),
    )
    .with(P.string, (d) => parseISO(d))
    .with(P.instanceOf(Date), (d) => d)
    .exhaustive();
}

type NormalizeDateInput = string | number | Date;
type NormalizeDateOpts = { numberHasMilliseconds: boolean };
