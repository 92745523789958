import { isSameDay, parseISO } from "date-fns";

import { ddMMMyyyyDate, isValidDateyyyyMMdd } from "@gdco/fe-core/util/date";
import { gdcoLocale } from "@gdco/fe-core/util/locale";
import { match } from "ts-pattern";

export const RELEASE_DATE_COMING_SOON_YEAR = 9999;
export const RELEASE_DATE_COMING_SOON = `${RELEASE_DATE_COMING_SOON_YEAR}-12-31`;
export const RELEASE_DATE_COMING_SOON_DATE = parseISO(RELEASE_DATE_COMING_SOON);
export const RELEASE_DATE_COMING_SOON_DEPRECATED_YEAR = 1970;
export const RELEASE_DATE_COMING_SOON_DEPRECATED = `${RELEASE_DATE_COMING_SOON_DEPRECATED_YEAR}-01-01`;
export const RELEASE_DATE_COMING_SOON_DEPRECATED_DATE = parseISO(
  RELEASE_DATE_COMING_SOON_DEPRECATED,
);

/**
 * Transforms a release date to a valid date or null
 *
 * Date in 1970 (old coming soon) is transformed to 9999-12-31 (new coming soon)
 */
export function transformReleaseDate(releaseDate: string | null) {
  return match(releaseDate)
    .with(null, () => null)
    .with(RELEASE_DATE_COMING_SOON_DEPRECATED, () => RELEASE_DATE_COMING_SOON)
    .when(
      (date) => isValidDateyyyyMMdd(date),
      (date) => date,
    )
    .otherwise(() => null);
}

/**
 * Renders a release date
 *
 * You should use {@link transformReleaseDate} before calling this function.
 *
 * - undefined|null: N/A
 * - 1970-01-01: Coming Soon
 * - 9999-12-31: Coming Soon
 * - else: dd MMM yyyy
 *
 * @example renderReleaseDate("2023-02-23") => "23 Feb 2023"
 * @example renderReleaseDate("1970-01-01") => "Coming Soon"
 * @example renderReleaseDate("9999-12-31") => "Coming Soon"
 * @example renderReleaseDate(null) => "N/A"
 */
export function renderReleaseDate(releaseDate: string | null | undefined) {
  let value: string;
  if (releaseDate === undefined || releaseDate === null) {
    value = gdcoLocale.na;
  } else if (
    releaseDate === RELEASE_DATE_COMING_SOON ||
    releaseDate === RELEASE_DATE_COMING_SOON_DEPRECATED
  ) {
    value = "Coming Soon";
  } else {
    value = ddMMMyyyyDate(releaseDate);
  }
  return value;
}

export function isComingSoon(releaseDate: Date | null | undefined) {
  if (releaseDate === undefined || releaseDate === null) {
    return false;
  }
  return isSameDay(releaseDate, RELEASE_DATE_COMING_SOON_DATE);
}

export enum ReleaseStatus {
  /** release_date < today */
  PRE_RELEASE = 1,
  /** release_date = today */
  DAY_OF_RELEASE = 2,
  /** release_date > today */
  POST_RELEASE = 3,
  /** app store page still valid, but no purchase option */
  RETIRED_ABANDONED = 4,
  /** app store page non-functional, redirects to Steam home page */
  INVALID_REMOVED = 5,
  /** not an app: hardware, subscription, etc. */
  NOT_APPLICABLE = 6,
}

export const releaseStatusText = {
  [ReleaseStatus.PRE_RELEASE]: "Pre-Release",
  [ReleaseStatus.DAY_OF_RELEASE]: "Day of Release",
  [ReleaseStatus.POST_RELEASE]: "Post-Release",
  [ReleaseStatus.RETIRED_ABANDONED]: "Retired/Abandoned",
  [ReleaseStatus.INVALID_REMOVED]: "Invalid/Removed",
  [ReleaseStatus.NOT_APPLICABLE]: "Not Applicable",
} satisfies Record<ReleaseStatus, string>;

export const isReleaseStatusPreRelease = (
  releaseStatus: ReleaseStatus,
): boolean => {
  return (
    releaseStatus === ReleaseStatus.PRE_RELEASE ||
    releaseStatus === ReleaseStatus.DAY_OF_RELEASE
  );
};

export const isReleaseStatusPostRelease = (
  releaseStatus: ReleaseStatus,
): boolean => {
  return (
    releaseStatus === ReleaseStatus.DAY_OF_RELEASE ||
    releaseStatus === ReleaseStatus.POST_RELEASE ||
    releaseStatus === ReleaseStatus.RETIRED_ABANDONED
  );
};

export enum EarlyAccessStatus {
  /**
   * Apps in pre-release: no early access phase is planned;
   * Apps in post-release: never was in early access
   */
  NEVER = 11,
  /** App is not purchasable, but early access release is planned */
  PLANNED = 12,
  /** App is purchasable and is currently released as early access */
  CURRENT = 13,
  /** App is purchasable and is out of early access i.e. is fully released */
  FORMER = 14,
}

export const earlyAccessStatusText = {
  [EarlyAccessStatus.NEVER]: "Never",
  [EarlyAccessStatus.PLANNED]: "Planned",
  [EarlyAccessStatus.CURRENT]: "Current",
  [EarlyAccessStatus.FORMER]: "Former",
} satisfies Record<EarlyAccessStatus, string>;
