import { noop } from "lodash";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useEventListener, useIsClient } from "usehooks-ts";

import { removeToken } from "../fetch/fetch-token";
import { reactQueryClient } from "../fetch/QueryClient";
import type { HasAnyRoleFn } from "../roles";
import type {
  LocalStorageCurrentTabEvent,
  LocalStorageDataKey,
  LocalStorageValue,
} from "../util/storage";
import {
  gdcoStorage,
  gdcoStorageData,
  getStorageKey,
  LOCAL_STORAGE_EVENT_CURRENT_TAB,
  LOCAL_STORAGE_EVENT_OTHER_TAB,
} from "../util/storage";
import { match } from "ts-pattern";

const UserContext = createContext<User>({
  user: null,
  isSignedIn: false,
  signOut: noop,
  hasAnyRole: () => false,
});

/**
 * Firebase user keys that are picked from the user object. Roles are added separately as they are not part of the user object.
 */
export const pickedFirebaseUserKeys = [
  "uid",
  "email",
  "displayName",
  "photoURL",
  "emailVerified",
  "metadata",
] as const;

export const LEGACY_LOGINS = new Set(["plus", "focusplus"]);

export type User = {
  /** Whether the user is signed in, automatically updated when the user changes */
  isSignedIn: boolean;
  /** The user object. Updated on load and when the local storage value changes. */
  user: LocalStorageValue<"userInfo"> | null;
  /** Sign out the user */
  signOut: () => void;
  /**
   * Whether the user is signed in and has any of the given roles
   *
   * If the user has the `admin` role, they are considered to have all roles, unless `opts.skipAdmin` is true.
   *
   * @param roles The roles to check
   *              - If null, always returns true
   *             - If empty array, returns false (for admin, depends on `opts.skipAdmin`)
   * @param opts Options
   */
  hasAnyRole: (
    roles: Parameters<HasAnyRoleFn>[1],
    opts?: Parameters<HasAnyRoleFn>[2],
  ) => boolean;
};

/**
 * Get the user context
 */
export function useUser() {
  return useContext(UserContext);
}

/**
 * Provider for the user context
 */
export const UserProvider = (props: UserProviderProps) => {
  const isClient = useIsClient();

  const [userInfo, setUserInfo] =
    useState<LocalStorageValue<"userInfo"> | null>(null);

  useEffect(() => {
    // Set the user info from local storage when the page loads

    const userToken = gdcoStorage.local.getItem("userToken");
    if (userToken === null) {
      // Force remove the user info from local storage in case it still exists
      gdcoStorage.local.removeItem("userInfo");
      return;
    }

    const userInfo = gdcoStorage.local.getItem("userInfo");
    if (userInfo === null) {
      // Force remove the user token from local storage in case it still exists
      gdcoStorage.local.removeItem("userToken");
      return;
    }

    if (LEGACY_LOGINS.has(userInfo.uid)) {
      // Legacy user info in Firebase auth flow, force remove the user token from local storage
      gdcoStorage.local.removeItem("userToken");
      return;
    }

    setUserInfo(userInfo);
  }, []);

  function doSignOut() {
    // Reset the user info when the user logs out
    setUserInfo(null);

    // Remove user-specific data from storage
    const keysToRemove: LocalStorageDataKey[] = ["appCompare"];
    for (const key of keysToRemove) {
      gdcoStorage.local.removeItem(key);
    }

    void reactQueryClient.clear();

    gdcoStorage.local.removeItem("userInfo");
  }

  useEventListener(
    LOCAL_STORAGE_EVENT_CURRENT_TAB,
    (event: LocalStorageCurrentTabEvent) => {
      if (event.detail.key === getStorageKey("userToken")) {
        const value = event.detail.value as LocalStorageValue<"userToken">;
        if (value === null) {
          doSignOut();
        }
      } else if (event.detail.key === getStorageKey("userInfo")) {
        // Set the user info from local storage when it changes
        const value = event.detail.value as LocalStorageValue<"userInfo">;
        setUserInfo(value);
      }
    },
  );

  useEventListener(LOCAL_STORAGE_EVENT_OTHER_TAB, (event) => {
    // Set the user info from local storage when it changes in another tab
    if (event.key === getStorageKey("userToken")) {
      const value = gdcoStorageData.userToken.schema.safeParse(event.newValue);
      if (!value.success || value.data === null) {
        doSignOut();
      }
    } else if (event.key === getStorageKey("userInfo")) {
      // Set the user info from local storage when it changes in another tab
      const value = gdcoStorageData.userInfo.schema.safeParse(event.newValue);
      setUserInfo(value.success ? value.data : null);
    }
  });

  const hasAnyRole: User["hasAnyRole"] = checkUserAccess(userInfo);

  // Prevent hydration mismatch by only rendering the children after the first render

  return (
    <UserContext.Provider
      value={{
        user: userInfo,
        isSignedIn: userInfo !== null,
        signOut: removeToken,
        hasAnyRole,
      }}
    >
      {isClient ? props.children : null}
    </UserContext.Provider>
  );
};

type UserProviderProps = {
  children: React.ReactNode;
};

/**
 * Check if the user has any of the given roles
 *
 * @param userInfo The user info to check
 * @returns A function that checks if the user has any of the given roles. Returns true if the user has access, false otherwise.
 */
export function checkUserAccess(
  userInfo: LocalStorageValue<"userInfo"> | null,
) {
  return ((requestedRoles, opts) => {
    const result = match({ userInfo, requestedRoles, opts })
      .returnType<{ canAccess: boolean; reason: string }>()
      .with({ opts: { allowSignedOut: true } }, () => {
        // Allow signed out users if opts.allowSignedOut is true
        return { canAccess: true, reason: "ALLOW_SIGNED_OUT" };
      })
      .with({ userInfo: null }, () => {
        // No user info, so access is always denied
        return { canAccess: false, reason: "NO_USER_INFO" };
      })
      .with({ requestedRoles: null }, () => {
        // No roles, so access is always allowed
        return { canAccess: true, reason: "NO_ROLES" };
      })
      .when(
        ({ userInfo }) =>
          userInfo?.roles.includes("admin") && opts?.ignoreAdmin !== true,
        ({ userInfo, requestedRoles, opts }) => {
          // Admins have access to everything but can have fake roles for presentation purposes

          if (userInfo === null) {
            // Shouldn't happen, but just in case
            return { canAccess: false, reason: "ADMIN_NO_USER_INFO" };
          }

          const fakeRoles = userInfo.fakeRoles ?? [];
          // Check fake roles
          if (fakeRoles.length > 0 && opts?.ignoreFakeRoles !== true) {
            if (requestedRoles === null) {
              return { canAccess: true, reason: "ADMIN_FAKE_ROLES_NO_ROLES" };
            }
            const hasRole = fakeRoles.some((role) =>
              requestedRoles.includes(role),
            );
            return { canAccess: hasRole, reason: "ADMIN_FAKE_ROLES" };
          }

          // Admins have access to everything
          return { canAccess: true, reason: "ADMIN" };
        },
      )
      .with({ requestedRoles: [] }, () => {
        // Empty roles, so access is always denied
        // Must be after the admin check to allow admin access
        return { canAccess: false, reason: "EMPTY_ROLES" };
      })
      .otherwise(({ userInfo, requestedRoles }) => {
        if (userInfo === null) {
          return { canAccess: false, reason: "OTHER_NO_USER_INFO" };
        }
        if (requestedRoles === null) {
          return { canAccess: false, reason: "OTHER_NO_ROLES" };
        }
        const hasRole = userInfo.roles.some((role) =>
          requestedRoles.includes(role),
        );
        return { canAccess: hasRole, reason: "OTHER" };
      });

    return result.canAccess;
  }) as User["hasAnyRole"];
}
